<template>
  <div>
    <v-row v-if="loading" justify="center">
      <v-col xs="12" xl="8">
        <h3 class="text-center">
          Lending Opportunity

          <v-progress-circular indeterminate color="primary" />
        </h3>
      </v-col>
    </v-row>

    <v-row v-if="!loading && viewable" justify="center">
      <v-col xs="12" xl="8">
        <!-- image -->
        <v-row>
          <v-col>
            <div class="image-container">
              <v-img
                src="@/assets/deal_summary.png"
                :aspect-ratio="aspectRatio"
                class="darkened-image"
              ></v-img>

              <div class="centered-text">
                <h1
                  :class="
                    windowWidth >= 960
                      ? 'text-center text-h3 font-weight-black'
                      : 'text-center text-h4 font-weight-black'
                  "
                >
                  Lending Opportunity
                </h1>
              </div>
            </div>
          </v-col>
        </v-row>

        <!-- header text -->
        <v-row>
          <v-col>
            <h1>Deal Information</h1>
            <p class="grey--text" v-if="!loading && viewable">
              Full address and application details will be provided upon
              interest in pursuing lending opportunity.
            </p>
          </v-col>
        </v-row>

        <Read_Deal_Summary
          @getDealInfo="getDealInfo"
          :company="company"
          :deal_summary_data="deal_summary_data"
          view_type="logged_in"
        />
      </v-col>
    </v-row>

    <v-row v-if="!loading && !viewable">
      <v-col>
        <v-alert type="warning"
          >This lending opportunity is not currently available for
          viewing.</v-alert
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import API from "@/plugins/API";
import filters from "@/plugins/filters";

import Read_Deal_Summary from "@/components/Read_Deal_Summary";

export default {
  props: ["deal_summary_id"],
  components: {
    Read_Deal_Summary,
  },

  data() {
    return {
      deal_summary_data: {},
      my_offers: [],
      company: {},
      viewable: false,
      loading: true,

      windowWidth: window.innerWidth,
    };
  },

  computed: {
    aspectRatio() {
      if (this.windowWidth >= 600) {
        return 3;
      } else if (this.windowWidth >= 960) {
        return 3;
      } else if (this.windowWidth >= 1264) {
        return 2.5;
      } else {
        return 1.5;
      }
    },
  },

  methods: {
    async getDealInfo() {
      try {
        let dealResponse = await API().get(
          `/api/user_read_deal_summary/${this.deal_summary_id}/`
          // `/api/deals/${this.dealId}/?populate[0]=deal_summary&populate[1]=deal_offers`
        );
        // console.log(dealResponse);

        this.deal_summary_data = dealResponse.data;
        this.company = this.$store.getters["Auth/getAuthUser"].companies[0];

        this.deal_summary_data.summary_offers.forEach((offer) => {
          if (offer.company.id == parseInt(this.company.id)) {
            offer.my_offer = true;
          } else {
            const auth_user = this.$store.getters[
              "Auth/getAuthUser"
            ].auth_companies.some((company) => company.id == offer.company.id);

            auth_user ? (offer.my_offer = auth_user) : (offer.my_offer = false);
          }
        });

        // iterate through existing offers and see if users company made the offer
        // this.my_offers = this.deal_summary_data.summary_offers.filter((offer)=>{
        //   if (offer.company.id == parseInt(this.company.id)){
        //     return offer
        //   }
        // })

        this.viewable = true;

        window.addEventListener("resize", this.handleResize);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.viewable = false;
        this.loading = false;
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    await this.getDealInfo();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },

  filters: {
    ...filters,
  },
};
</script>

<style lang="scss" scoped>
tr:hover {
  background-color: transparent !important;
}

.image-container {
  position: relative;
}

.darkened-image {
  filter: brightness(50%);
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
  color: #ff6633;
  font-size: 1.5em;
  font-weight: bold;
}
</style>
